@import "../../assets/styles/commonStyles";

.avatar {
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 12.5rem;
  display: block;
  border: 4px solid $color-grey;
  box-sizing: border-box;
}
