@import "../../assets/styles/commonStyles";

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
  max-width: 26rem;
  margin: 0 auto;

  h1 {
    font-weight: normal;
    font-size: 2rem;
    color: $color-white;
    margin: 0;
    padding: 1rem 0 0;
    text-align: center;
  }

  h2 {
    font-weight: 500;
    font-size: 0.875rem;
    color: $color-white;
    margin: 0;
    padding: .5rem 0 0;
    text-align: center;
  }

  .socialIconGroup {
    padding: 2rem 0 0;
  }

  .socialIcon {
    margin: 0 0.25rem;
  }

  .workExperiences {
    padding: 4rem 2rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
