@import "../../assets/styles/commonStyles";

.workExperienceCard {
  background: $color-darkest-blue;
  border-radius: 1rem;

  .logoArea {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $color-grey-low-opacity;
    height: 4.25rem;
    padding: 0;
    margin: 0;

    img {
      height: 2.25rem;
    }
  }

  .description {
    font-family: $font-euclid;
    font-size: 0.813rem;
    color: $color-white;
    line-height: 1.125rem;
    margin: 0;
    padding: 1rem 1rem 1.5rem;
  }
}
