@import "assets/styles/commonStyles";

:global(html, body) {
  margin: 0;
  padding: 0;
}

:global(html) {
  background: $color-dark-blue;
  font-size: 16px;
  color: $color-white;
  font-family: $font-euclid;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: local('EuclidCircularA-Bold'),
  url('./assets/fonts/EuclidCircularA-Bold.woff2') format('woff2'),
  url('./assets/fonts/EuclidCircularA-Bold.woff') format('woff'),
  url('./assets/fonts/EuclidCircularA-Bold.ttf') format('truetype'),
  url('./assets/fonts/EuclidCircularA-Bold.svg#EuclidCircularA-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: local('EuclidCircularA-Italic'),
  url('./assets/fonts/EuclidCircularA-Italic.woff2') format('woff2'),
  url('./assets/fonts/EuclidCircularA-Italic.woff') format('woff'),
  url('./assets/fonts/EuclidCircularA-Italic.ttf') format('truetype'),
  url('./assets/fonts/EuclidCircularA-Italic.svg#EuclidCircularA-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: local('EuclidCircularA-BoldItalic'),
  url('./assets/fonts/EuclidCircularA-BoldItalic.woff2') format('woff2'),
  url('./assets/fonts/EuclidCircularA-BoldItalic.woff') format('woff'),
  url('./assets/fonts/EuclidCircularA-BoldItalic.ttf') format('truetype'),
  url('./assets/fonts/EuclidCircularA-BoldItalic.svg#EuclidCircularA-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: local('EuclidCircularA-Medium'),
  url('./assets/fonts/EuclidCircularA-Medium.woff2') format('woff2'),
  url('./assets/fonts/EuclidCircularA-Medium.woff') format('woff'),
  url('./assets/fonts/EuclidCircularA-Medium.ttf') format('truetype'),
  url('./assets/fonts/EuclidCircularA-Medium.svg#EuclidCircularA-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: local('EuclidCircularA-MediumItalic'),
  url('./assets/fonts/EuclidCircularA-MediumItalic.woff2') format('woff2'),
  url('./assets/fonts/EuclidCircularA-MediumItalic.woff') format('woff'),
  url('./assets/fonts/EuclidCircularA-MediumItalic.ttf') format('truetype'),
  url('./assets/fonts/EuclidCircularA-MediumItalic.svg#EuclidCircularA-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: local('EuclidCircularA-LightItalic'),
  url('./assets/fonts/EuclidCircularA-LightItalic.woff2') format('woff2'),
  url('./assets/fonts/EuclidCircularA-LightItalic.woff') format('woff'),
  url('./assets/fonts/EuclidCircularA-LightItalic.ttf') format('truetype'),
  url('./assets/fonts/EuclidCircularA-LightItalic.svg#EuclidCircularA-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: local('EuclidCircularA-Light'),
  url('./assets/fonts/EuclidCircularA-Light.woff2') format('woff2'),
  url('./assets/fonts/EuclidCircularA-Light.woff') format('woff'),
  url('./assets/fonts/EuclidCircularA-Light.ttf') format('truetype'),
  url('./assets/fonts/EuclidCircularA-Light.svg#EuclidCircularA-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: local('EuclidCircularA-SemiBold'),
  url('./assets/fonts/EuclidCircularA-SemiBold.woff2') format('woff2'),
  url('./assets/fonts/EuclidCircularA-SemiBold.woff') format('woff'),
  url('./assets/fonts/EuclidCircularA-SemiBold.ttf') format('truetype'),
  url('./assets/fonts/EuclidCircularA-SemiBold.svg#EuclidCircularA-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: local('EuclidCircularA-Regular'),
  url('./assets/fonts/EuclidCircularA-Regular.woff2') format('woff2'),
  url('./assets/fonts/EuclidCircularA-Regular.woff') format('woff'),
  url('./assets/fonts/EuclidCircularA-Regular.ttf') format('truetype'),
  url('./assets/fonts/EuclidCircularA-Regular.svg#EuclidCircularA-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: local('EuclidCircularA-SemiBoldItalic'),
  url('./assets/fonts/EuclidCircularA-SemiBoldItalic.woff2') format('woff2'),
  url('./assets/fonts/EuclidCircularA-SemiBoldItalic.woff') format('woff'),
  url('./assets/fonts/EuclidCircularA-SemiBoldItalic.ttf') format('truetype'),
  url('./assets/fonts/EuclidCircularA-SemiBoldItalic.svg#EuclidCircularA-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: block;
}

